import * as React from 'react';
import { HeaderAuth } from '../../components/HeaderAuth';
import { InterfaceModules } from '@brainysoft/lk-components';
import { useTranslation } from '../../utils/useTranslation';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useInterfaceModuleSetting } from '../../utils/useInterfaceModuleSetting';
import { useDisplayMode } from '../../utils/useDisplayMode';
import { ILayout } from '@brainysoft/lk-components';
import Head from 'next/head';
import { sanitizer } from '../../utils/sanitizer';

export const Layout: React.FC<React.PropsWithChildren<ILayout>> = (props) => {
  useDisplayMode();

  const authPageStyles = useThemeStyles('AuthPage');
  const layoutStyles = useThemeStyles('Layout');

  const { t, keyExists, tAsArray } = useTranslation();

  const disclaimerSettings = useInterfaceModuleSetting(InterfaceModules.AUTH_PAGE_DISCLAIMER);

  const html = keyExists('widgets:authPageDisclaimer.html') ? t('widgets:authPageDisclaimer.html') : undefined;

  return (
    <React.Fragment>
      <Head>
        <title>{props.title}</title>
      </Head>
      <HeaderAuth />
      <div className={`content-wrap ${authPageStyles.authPageWrap ? authPageStyles.authPageWrap : ''}`}>
        <div className={authPageStyles.authBackgroundImage} />
        {props.children}
        {disclaimerSettings?.active && keyExists('widgets:authPageDisclaimer') && !!html && (
          <div className={layoutStyles.authDisclaimer} dangerouslySetInnerHTML={{ __html: sanitizer(html) }} />
        )}
        {disclaimerSettings?.active && keyExists('widgets:authPageDisclaimer') && !html && (
          <div className={layoutStyles.authDisclaimer}>
            <h4 className={'header'}>{t('widgets:authPageDisclaimer.header')}</h4>
            {tAsArray('widgets:authPageDisclaimer.text').map((text, index) => (
              <div className={'text'} key={index}>
                {text}
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
