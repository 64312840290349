import * as React from 'react';
import { useTranslation } from '../../utils/useTranslation';
import { ProcessFormsType, ProcessType } from '@brainysoft/lk-components';
import { cacheService as Storage } from '@brainysoft/lk-components';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/Button';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useRouter } from 'next/router';
import { useProcessForms } from '../../utils/useProcessForms';
import { useGoogleAnalytics } from '../../integrations/useGoogleAnalytics';
import { useYandexMetrika } from '../../integrations/useYandexMetrika';

export const AuthView: React.FC<{ externalAuth?: boolean }> = (props) => {
  const classes = useThemeStyles('AuthPage');

  const ga = useGoogleAnalytics();
  const ym = useYandexMetrika();

  const { t } = useTranslation();
  const router = useRouter();

  const isExternalLead = !!router.query?.externalLead;

  const { renderersMap } = useProcessForms(ProcessFormsType.AUTH);

  const AuthForm = renderersMap['authForm'] ?? (() => <></>);

  const [modalVisible, setModalVisible] = React.useState(false);

  const registrationHandler = async () => {
    const uuid = await Storage.getItem(`process:${ProcessType.REGISTRATION}:uuid`);
    const session = await Storage.getItem(`process:${ProcessType.REGISTRATION}:session`);
    if (uuid && session) {
      setModalVisible(true);
    } else {
      ga.send('registration');
      ym.send('registration');
      await router.push({ pathname: '/registration' });
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleClick = async (value) => {
    if (value === 'continue') {
      await router.push({ pathname: '/registration' });
    } else {
      await router.push({ pathname: '/registration', query: { forceNewProcess: true } });
    }
  };

  const onApprove = async () => {
    ga.send('login');
    ym.send('login');

    if (isExternalLead) {
      router.push({ pathname: '/loan-app/create' });
    } else {
      router.push({ pathname: '/' });
    }
  };

  return (
    <div className={classes.modal}>
      <div className={classes.authFormWrapper}>
        <AuthForm onApprove={onApprove} onRegistrationClick={registrationHandler} />

        <Modal
          title={t('auth:continueRegistration')}
          centered
          open={modalVisible}
          onCancel={handleCancel}
          className={classes.antdModal}
          footer={[
            <Button key='back' onClick={() => handleClick('new')}>
              {t('auth:shouldReset')}
            </Button>,
            <Button key='submit' type='primary' onClick={() => handleClick('continue')}>
              {t('auth:continue')}
            </Button>,
          ]}
        >
          {t('auth:message.registerNotCompleted')}
        </Modal>
      </div>
    </div>
  );
};

AuthView.whyDidYouRender = true;
