import sanitizeHtml from 'sanitize-html';

export const sanitizer = (html: string): string => {
  return sanitizeHtml(html, {
    allowedTags: ['div', 'span', 'a', 'p', 'b', 'i', 'em', 'strong', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    allowedAttributes: {
      a: ['href', 'rel', 'target'],
      span: ['class'],
      div: ['class'],
    },
  });
};
