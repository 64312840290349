import { NextPage } from 'next';
import * as React from 'react';
import { AuthView } from '../../src/views/AuthView';
import { Layout } from '../../src/layouts/auth';
import { useActivePage } from '../../src/utils/useActivePage';
import { Chatra } from '../../src/integrations/Chatra';
import { PixelKbki } from '../../src/integrations/PixelKbki';
import { useTranslation } from '../../src/utils/useTranslation';

const AuthPage: NextPage = () => {
  useActivePage('auth');
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <PixelKbki />
      <Chatra />

      <Layout title={t(`auth:title`)}>
        <AuthView />
      </Layout>
    </React.Fragment>
  );
};
AuthPage.whyDidYouRender = true;
export default AuthPage;
