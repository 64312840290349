import * as React from 'react';
import Logo from '../Logo';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { getCustomComponents } from '../../utils/getCustomComponents';

export const HeaderAuth: React.FC = () => {
  const classes = useThemeStyles('HeaderAuth');

  const HeaderPhone = getCustomComponents('HeaderPhone');

  return (
    <header className={classes.headerWrap}>
      <Logo type='auth' />
      <HeaderPhone auth={true} />
    </header>
  );
};
